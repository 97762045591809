<template>
  <div class="equipment">
    <!-- 导航 -->
    <div class="equipment-nav">
      <!-- <div class="eq-theme">{{ name }}</div> -->
      <div
        class="eq-tit"
        :class="{ activeClass: currentIndex === index }"
        @click="changeActive(index, item)"
        v-for="(item, index) in equipmentList"
        :key="index"
      >
        <!-- <i class="el-icon-arrow-right"></i> -->
        {{ item.name }}
      </div>
    </div>
    <!-- 设备 -->
    <div
      class="equipment-introduce"
      v-if="equipmentContent && equipmentContent.pageDatas.length > 0"
    >
      <div
        class="equipment-carouselBox"
        style="width: 450px; height: 330px; position: relative"
      >
        <el-carousel width="450" :interval="2500">
          <el-carousel-item
            v-for="(item, index) in equipmentContent.pageDatas"
            :key="index"
          >
            <img
              @click="preview(index)"
              style="min-width: 450px; height: 100%"
              :src="item.img"
              alt=""
            />
            <div class="ei-title">{{ item.title }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="ei-word">
        <h5 class="ei-theme">{{ equipmentContent.name }}</h5>
        <i></i>
        <div class="ei-text">{{ equipmentContent.img }}</div>
      </div>

      <!-- 下载文件的按钮 -->
      <div class="downLoad" @click="downLoad">
        <!-- <el-button icon="el-icon-download" type="danger" circle></el-button> -->
        <div v-if="lang == 'CN'">资料下载 <i class="el-icon-bottom"></i></div>
        <div v-else>download <i class="el-icon-bottom"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      name: "",
      equipmentList: [],
      equipmentContent: null,
      currentIndex: 0,
      lang: "CN",
      srcList: [], //图片预览列表
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      this.lang = lang;
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId:
              this.nodeId[this.$route.query.titleId] ||
              this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.name = res.data.data.name;
            if (res.data.data.titles) {
              this.equipmentList = res.data.data.titles;
              this.equipmentContent = this.equipmentList[0];
              this.srcList = this.equipmentContent.pageDatas.map(
                (src) => src.img
              );
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    changeActive(index, item) {
      this.equipmentContent = item;
      this.srcList = this.equipmentContent.pageDatas.map((src) => src.img);
      this.currentIndex = index;
    },
    downLoad() {
      let pageData = this.equipmentContent.pageDatas[0];
      if (pageData.filePath) {
        let filePathList = JSON.parse(pageData.filePath);
        if (filePathList.length > 0) {
          window.location.href =
            "http://edu.siwonet.com:8099/downLoadFiles?pageDataId=" +
            pageData.pageDataId;
          this.$notify.success("文件正在下载，请稍后~");
        } else {
          this.$message("暂无下载文件");
        }
      } else {
        this.$message("暂无下载文件");
      }
    },
    // 图片预览
    preview(index) {
      this.$imagePreview({
        images: this.srcList,
        index: index,
      });
    },
  },
  computed: {
    ...mapState(["nodeId"]),
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.equipment {
  // width: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  .equipment-nav {
    width: 250px;
    margin-right: 50px;
    .eq-theme {
      color: rgb(255, 255, 255);
    }
    .eq-theme,
    .eq-tit {
      height: 50px;
      color: rgb(0, 0, 0);
      line-height: 50px;
      font-size: 18px;
      // padding-left: 20px;
      // box-sizing: border-box;
    }
    .eq-tit {
      cursor: pointer;
      font-size: 15px;
      border-bottom: 1px solid rgba(226, 9, 20, 0.8);
    }
  }
  .equipment-introduce {
    height: 330px;
    display: flex;
    // justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    position: relative;
    /deep/.el-carousel__button {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    .ei-title {
      // width: 100%;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 30px;
      color: rgb(255, 255, 255);
      background-color: rgba(255, 0, 0, 0.6);
      padding: 0 10px;
      box-sizing: border-box;
    }
    .ei-word {
      width: 500px;
      padding: 30px;
      box-sizing: border-box;
      .ei-theme {
        font-size: 20px;
        color: #090909;
        font-weight: normal;
      }
      i {
        display: block;
        width: 18px;
        height: 4px;
        background: #d80930;
        margin-top: 16px;
        margin-bottom: 18px;
      }
      .ei-text {
        font-size: 15px;
        line-height: 36px;
        color: #4d4d4d;
        height: 180px;
        overflow: auto;
        padding-right: 10px;
        box-sizing: border-box;
      }
    }
    .downLoad {
      position: absolute;
      bottom: 10px;
      right: 20px;
      color: rgb(0, 81, 255);
      border-bottom: 1px solid rgb(0, 81, 255);
      padding-bottom: 5px;
      cursor: pointer;
      display: none;
    }
  }
}
</style>
<style>
.equipment-introduce .el-carousel__container {
  height: 330px;
}
/* charset "utf-8"; */
::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}
/* 预览样式 */
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.pswp__button--arrow--right:before {
  background-position: -85px -35px;
  right: 50px;
}
.pswp__button--arrow--left:before {
  background-position: -130px -35px;
  left: 50px;
}
</style>
